import { Button } from '@/components/ui/button'
import { Textarea } from '@/components/ui/textarea'
import { CopyIcon } from 'lucide-react'
import { useMemo, useState } from 'react'
import { ReloadIcon } from '@radix-ui/react-icons'
import { refactor } from '@/utils/requests'
import { useToast } from '@/components/ui/use-toast'

const useRefactor = ({ originalText }: { originalText: string }) => {
  const [isLoading, setIsLoading] = useState(false)
  const [modifiedText, setModifiedText] = useState<string | null>(null)
  const { toast } = useToast()
  const doRefactor = async () => {
    setIsLoading(true)
    try {
      const res = await refactor(originalText)
      setModifiedText(res)
    } catch {
      toast({
        title: 'Something went wrong!',
        description: 'Failed to paraphrase. Please try again in a few minutes',
      })
    } finally {
      setIsLoading(false)
    }
  }

  return {
    isLoading,
    doRefactor,
    modifiedText,
  }
}

export const Playground = () => {
  const [inputText, setInputText] = useState('')

  const { modifiedText, isLoading, doRefactor } = useRefactor({
    originalText: inputText,
  })

  const defaultValue = useMemo(() => {
    return isLoading
      ? 'Please wait while we paraphrase to avoid detection. Sometimes it takes up to two minutes for our advanced AI to run'
      : ''
  }, [isLoading])

  const { toast } = useToast()

  return (
    <div className="px-16 py-4">
      <h2 className="mb-8 text-center">
        Avoid detection by ZeroGPT, Turnitin etc. Join our discord for higher
        character limit.
      </h2>
      <div className="flex flex-row gap-16">
        <div className="w-full flex flex-col gap-4">
          <Textarea
            value={inputText}
            onChange={(event) => setInputText(event.target.value)}
            disabled={isLoading}
            className="h-full resize-none h-[60vh]"
            defaultValue="Enter something here"
          />
          <div className="flex items-center justify-between">
            <Button
              type="submit"
              size="sm"
              className="px-3"
              variant="secondary"
              onClick={async () => {
                const text = await navigator.clipboard.readText()
                setInputText(text)
              }}
            >
              <span className="sr-only">Copy</span>
              <CopyIcon className="h-4 w-4" />
            </Button>
            {isLoading ? (
              <Button disabled size="sm">
                <ReloadIcon className="mr-2 h-4 w-4 animate-spin" />
                Please wait
              </Button>
            ) : (
              <Button type="submit" size="sm" onClick={doRefactor}>
                Refactor
              </Button>
            )}
          </div>
        </div>
        <div className="flex flex-col w-full gap-4">
          <Textarea
            disabled={isLoading}
            className="resize-none h-[60vh]"
            readOnly
            value={modifiedText ?? defaultValue}
          />
          <div className="flex items-center justify-between">
            <Button
              type="submit"
              size="sm"
              className="px-3"
              variant="secondary"
              onClick={async () => {
                await navigator.clipboard.writeText(modifiedText ?? '')
                toast({
                  title: 'Copied to clipboard!',
                })
              }}
            >
              <span className="sr-only">Copy</span>
              <CopyIcon className="h-4 w-4" />
            </Button>

            <Button
              type="submit"
              size="sm"
              className="px-3"
              onClick={doRefactor}
            >
              <span className="sr-only">Reload</span>
              <ReloadIcon className="h-4 w-4" />
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}
