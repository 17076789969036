import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App'
import { ThemeProvider } from './components/theme-provider'
import './index.css'
import { Toaster } from './components/ui/toaster'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <React.StrictMode>
    <ThemeProvider defaultTheme="dark">
      <Toaster />
      <App />
    </ThemeProvider>
  </React.StrictMode>
)
